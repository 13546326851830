.to-link:hover{
  color:$primary !important;
  text-decoration: underline;
  cursor: pointer;
}
.to-link-white:hover{
  color:$white !important;
  cursor: pointer;
}
.search {
  position: absolute;
  right: 0;
}
span ul.rc-pagination.paginator {
  float: right;
}
.options {
  top: 20px;
}
.border-solid-1{
  border: 1px solid #d1d3d6;
  margin-bottom: 7px;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
}
.fixed-form{
  width: 10%;
  position: absolute;
  right: 19px;
}
.card-header {
  padding: 0.75rem 1.25rem;
  height: 56px;
  margin-bottom: 0;
  border: none;
  /*border-bottom: 1px solid #c8ced3;*/
}
.selectAll.form-check-input{
  position: relative;
  margin-top:0;
  margin-left:0;
}
.rc-select-single:not(.rc-select-customize-input) .rc-select-selector{
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem !important;
}
.rc-select-single .rc-select-selector {
  display: flex;
  position: relative;
}
.rc-select-single .rc-select-selector .rc-select-selection-search {
  width: 100%;
}
.rc-select-single .rc-select-selector .rc-select-selection-item, .rc-select-single .rc-select-selector .rc-select-selection-placeholder {
  position: absolute;
  top: 1px;
  left: 3px;
  pointer-events: none;
}
.rc-select-single:not(.rc-select-customize-input) .rc-select-selector .rc-select-selection-search-input {
  border: none;
  outline: none;
  background: rgba(255, 0, 0, 0.2);
  width: 100%;
}
.rc-select-dropdown {
  border: 1px solid green;
  min-height: 100px;
  position: absolute;
  background: #fff;
}
.rc-select-item-option-active {
  background: $primary;
  cursor: pointer;
  color:#fff
}
.rc-select-item {
  font-size: 15px;
  line-height: 1.5;
  padding: 4px 16px;
}
.rc-select-item-option-state-icon{
  display: none;
}
.rc-pagination-options-size-changer.rc-select {
  width: 118px !important;
}
.rc-pagination-item-active{
  border-color: $defaul !important;
}
.btn-columns{
  border-color: $gray-border;
  color: $black;
}
.select-row:hover{
  cursor: pointer;
  background: #f1f4f6;
}
.rw-list-option.rw-state-focus{
  background-color: transparent !important;
  border-color: transparent !important;
  color: $dark;
}
.rw-list-option.rw-state-focus:hover{
  color: #000 !important;
  background-color: transparent !important;
}

.rt-expander-left, .rt-expander-right{
  display: inline-block;
  position: relative;
  color: transparent;
  margin-left: 4px;
}
.rt-expander-left:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(0deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(108, 117, 125, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.rt-expander-right:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-180deg);
  transform: translate(-50%, -50%) rotate(-180deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(108, 117, 125, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.btn-plus-phone{
  position: absolute;
  left: -14px;
  top: 5px;
  padding: 7px;
  border-color: #ced4da;
}
.btn-minus-phone{
  top: 4px;
  right: -9px;
  padding: 7px;
}
.btn-type-phone{
  margin-left: -28px;
}
/*Class css para el borde del input search*/
//.css-1szy77t-control{
//  box-shadow: 0 0 0 1px #cccccc !important;
//  border-color: #cccccc !important;
//}
//.css-1szy77t-control:hover{
//  border-color: #cccccc !important;
//}
//input[type=checkbox]:checked:after {
//  content: " ";
//  color: #fff;
//  background-color: #db1f13 !important;
//  position: absolute;
//  width: 14px;
//  height: 15px;
//  border-radius: 18%;
//  left: 0;
//  visibility: visible;
//}
//input[type=checkbox]:checked:after {
//  content: "\2714";
//  font-size: 11px;
//  top: 4px;
//  padding-left: 3px;
//  padding-bottom: 0;
//  font: '-webkit-mini-control';
//}
//.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
//  width: 36%;
//}
.smooth-dnd-container{
  min-height: 106px !important;
}
.disabled-link{
  pointer-events: none;
}
ul.todo-list-wrapper .list-group-item{
  //background: #f9f9f9 !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border: 1px solid rgba(0,0,0,0.125);
}
.smooth-dnd-container.horizontal{
  width: 100%;
}

.sweet-alert button{
  border-color: transparent !important;
}
.css-1szy77t-control:hover{
  border-color: $primary !important;
}
.flag-select__option__icon {
  top: 0 !important;
}
.modal-header:hover{
  cursor: pointer;
}
.border-color-solid{
  border: 1px solid $primary-color;
}
//.form-check .form-check-input:checked ~ .form-check-label::after {
//  background-color: #333 !important;
//  border-color: #333 !important;
//}

 *, *::before, *::after {
   box-sizing: border-box;
   //box-shadow: none !important;
 }

.loading-indicator {
  text-align: center;
}

.loading-bullet {
  display: inline-block;
  opacity: 0;
  font-size: 2em;
  color: #02a17c;
}

.block-ui {
  position: relative;
  min-height: 3em;
}

.block-ui-container {
  position: absolute;
  z-index: 1010;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  min-height: 2em;
  cursor: wait;
  overflow: hidden;
}

.block-ui-container:focus {
  outline: none;
}

.block-ui-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  background-color: white;
}

.block-ui-message-container {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
  z-index: 10001;
}

.block-ui-message {
  color: #333;
  background: none;
  z-index: 1011;

  display: flex;
  align-items: center;
  justify-content: center;
}

.block-overlay-dark {
  .block-ui-overlay {
    background: rgba(0,0,0,1);
  }
}

.block-overlay-light {
  .block-ui-overlay {
    background: #f0f3f5;
  }
}
